import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import background from './new.png'
//import Button from '../Components/FormsUI/Button';



const Home = (props) => {

  const history = useHistory();
  const onSubmit = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  return (

    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '93vh', backgroundImage:"url(" + background + ")" , width:"100%", backgroundRepeat:"no-repeat", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}
      >

        <Box
          minHeight="250px"
          minWidth="400px"
          border={1}
          borderColor="white"
          borderRadius="12px"
        >
          <div style={{ textAlign: "center", padding: 20, background: "#009688", borderRadius:10 }}>
            <Typography variant="h4" component="h4" style={{ color: "#fff", textAlign: "center" }}>
              Terms of Service
            </Typography>
          </div>

          <div style={{ maxWidth: 600, color: '#009688', padding: 5, background:"#fff", borderRadius:10  }} className="terms">
            <span> 1. Please read the form carefully and fill in the correct information.</span>
            <p> 2. All star marked fields must be filled.</p>
            <p> 3. Re-verify all the information after completing the form, the authority has the right to cancel the form in case of any discrepancy in the information.</p>
            <form onSubmit={onSubmit}>
              <p><input type="checkbox" className="jss66" required name="terms" /> I accept the <u>Terms and Conditions</u></p>
              <div style={{textAlign:"right"}}>
              <p><Button variant="contained" color="secondary" type="submit">
                Submit
              </Button></p>
              </div>
  
            </form>
          </div>
        </Box>
       
      </Grid>
    </>
  );
};

export default Home;