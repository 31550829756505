import React from 'react';
import { Toolbar, AppBar, Typography, Container, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useReactRouter from 'use-react-router';
const AppMenu = (props) => {
  // console.log('hello', props)
  const history = useHistory();
  const { match } = useReactRouter();

  function goHome() {
    history.push("/login");
  }
  function viewApplication() {
    history.push("/online-applicant-info/" + parseInt(match.params.id));
  }
  function viewPayment() {
    window.open(
      'https://studentsheba.com',
      '_blank' // <- This is what makes it open in a new window.
    );
    // history.push("/online-applicant-fee-payment/" + parseInt(match.params.id));
  }

  function viewDownload() {
    window.open(
      `${process.env.REACT_APP_API_ROOT}/public/confirmation/letter/download/for/college?identificationId=${props.details.identificationId}&instituteId=${props.details.instituteId}`,
      '_blank' // <- This is what makes it open in a new window.
    );
    //history.push("/online-applicant-confirmation-paper/");
  }

  return (
    <>
      <div style={{ display: "flex", marginBottom: "20px", marginTop: "20px", justifyContent: "center" }}>
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => goHome()}> Home</Button>
        {/* <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => viewApplication()}> Application</Button> */}
        <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={() => viewPayment()}> Payment</Button>
        {/* {props.details.downloadFlag !== 0 && <Button variant="contained" color="primary" onClick={() => viewDownload()}> Download</Button>} */}
      </div>

    </>
  );
};

export default AppMenu;