export const genederData = {
  "Male": "Male",
  "Female": "Female",
  "Other": "Other",
}

export const religionData = {
  "Islam": "Islam",
  "Hinduism": "Hinduism",
  "Buddist": "Buddist",
  "Christian": "Christian",
  "Other": "Other",
}

export const quotaData = {
  "Not Applicable": "Not Applicable",
  "BGB": "BGB",
  "Army": "Army",
  "Police": "Police",
  "Freedom Fighter": "Freedom Fighter",
  "Tribes": "Tribes",
  "BKSP": "BKSP",
  "Abroad": "Abroad",
}

export const bloodData = {
  "A+": "A+",
  "A-": "A-",
  "AB+": "AB+",
  "AB-": "AB-",
  "B+": "B+",
  "B-": "B-",
  "O+": "O+",
  "O-": "O-",
}

export const autismData = {
  0: "No",
  1: "Yes",
}

export const fatheroccupationdata = {
  "Business": "Business",
  "Service": "Service",
  "Private Service": "Private Service",
  "Govt. Service": "Govt. Service",
  "Teacher": "Teacher",
  "Banker": "Banker",
  "Doctor": "Doctor",
  "Engineer": "Engineer",
  "Lawyer": "Lawyer",
  "Journalist": "Journalist",
  "Farmer": "Farmer",
  "Driver": "Driver",
  "Physician": "Physician",
  "Army": "Army",
  "Police Officer": "Police Officer",
  "Navy": "Navy",
  "Airforce": "Airforce",
  "Retired Person": "Retired Person",
  "NRB": "NRB",
  "BGB": "BGB",
  "N/A": "N/A",
  "NSI": "NSI",
}
export const motheroccupationdata = {
  "House Wife": "House Wife",
  "Business": "Business",
  "Service": "Service",
  "Private Service": "Private Service",
  "Govt. Service": "Govt. Service",
  "Teacher": "Teacher",
  "Banker": "Banker",
  "Doctor": "Doctor",
  "Engineer": "Engineer",
  "Lawyer": "Lawyer",
  "Journalist": "Journalist",
  "Farmer": "Farmer",
  "Driver": "Driver",
  "Physician": "Physician",
  "Army": "Army",
  "Police Officer": "Police Officer",
  "Navy": "Navy",
  "Airforce": "Airforce",
  "Retired Person": "Retired Person",
  "NRB": "NRB",
  "BGB": "BGB",
  "N/A": "N/A",
  "NSI": "NSI",
}

export const examNameData = {
  "SSC": "SSC",
  "Dakhil": "Dakhil",
  "Vocational ": "Vocational",
  "Others": "Others",
}

export const examGroupData = {
  "Science": "Science",
  "Humanities": "Humanities",
  "Business Studies": "Business Studies",
  "General": "General",
  "Other": "Other",
}

export const gradeData = {
  "A+": "A+",
  "A": "A",
  "A-": "A-",
  "B": "B",
  "C": "C",
  "D": "D",
}

export const boardData = {
  "Barisal": "Barisal",
  "Chittagong": "Chittagong",
  "Comilla": "Comilla",
  "Dhaka": "Dhaka",
  "Dinajpur": "Dinajpur",
  "Jessore": "Jessore",
  "Mymensingh": "Mymensingh",
  "Rajshahi": "Rajshahi",
  "Sylhet ": "Sylhet",
  "Technical ": "Technical",
  "Madrasah": "Madrasah",
}