import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useReactRouter from 'use-react-router';
import { Button as Buttonh } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { TextField } from '@material-ui/core';
import AppMenu from '../Components/Header/appmenu';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  root: {
    color: green[900],
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)" // default
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #C4C4C4" // customized
      }
    }
  }
}));



const PaymentForm = () => {
  const classes = useStyles();
  const [insDetails, setinsDetails] = useState();
  const { historyx, location, match } = useReactRouter();
  const info = JSON.parse(sessionStorage.getItem('info'));
  if (info === null) {
    history.push("/login");
  }
  const [textid, settextid] = useState();
  const [payId, setpayId] = useState();
  const history = useHistory();
  useEffect(() => {
    let id = match.params.id;

    async function fetchMyAPI() {
      let response = await fetch('https://shebashikkha.com/public/institute/list')
      response = await response.json()
      // console.log(response.item)
      let ins = response.item.filter(item => item.instiltuteId == parseInt(id))
      // console.log(ins?.[0]);
      setinsDetails(ins?.[0])
    }

    fetchMyAPI();

  }, []);

  const [paymentStatus, setpaymentStatus] = useState(false)
  const [show, setShow] = useState(false);
  const [first, setfirst] = useState(false);
  const [details, setdetails] = useState('')

  function formSubmit(e) {
    e.preventDefault();
    setfirst(true);
    fetch(`${process.env.REACT_APP_API_ROOT}/public/single/applicant/info/by/applicant-id?applicantId=${textid}`)
      .then(response =>
        response.json()
      ).then(data => {
        if (data?.messageType === 1) {
          setdetails(data?.item);
          setShow(true);
          setpayId(parseInt(data?.item?.registrationId))
          if (data?.item?.paidStatus === 1) {
            setpaymentStatus(true)
          } else {
            setpaymentStatus(false)
          }
        } else {
          setShow(false);
        }

      })
      .catch(error => {
        console.log(error)
      });
  }



  function paymentPage() {
    var win = window.open('https://shebashikkha.com/public/online/application-fee/pay?registrationId=' + payId);
    var timer = setInterval(function () {

      if (win.closed) {
        clearInterval(timer);
        fetch('https://shebashikkha.com/public/single/applicant/info?registrationId=' + payId + "&instituteId=" + parseInt(match.params.id))
          .then(response =>
            response.json()
          ).then(data => {
            if (data?.item?.paymentStatus === "Paid") {
              setpaymentStatus(true)
            }
          })
          .catch(error => {
            console.log(error)
          });
      }
    }, 3000);
  }

  function downloadPdf() {
    window.open(`${process.env.REACT_APP_API_ROOT}/public/confirmation/letter/download/for/college?instituteId=${parseInt(match.params.id)}&applicantId=${textid}`);
  }

  function viewForm() {
    history.push("/online-applicant-info/" + parseInt(match.params.id));
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header details={{ instituteName: info?.instituteName, address: info?.instituteAddress, image:info?.instituteImage }} />
      </Grid>
      <div style={{ background: "#fff", boxShadow: "rgb(216 216 216) 0px 0px 15px 0px", padding: "15px", width: "100%" }}>
        <AppMenu />
        <Divider />
        <br />
        <Grid item xs={12}>
          <Typography style={{ fontSize: "24px", textAlign: "center", marginBottom: "10px", color: "#009688", fontWeight: "500" }}>
            Payment
          </Typography>
        </Grid>
        <br />
        <Divider />
        <br />
        <Grid item xs={12}>
          <Container maxWidth="md">
            <form onSubmit={formSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField id="outlined-basic" label="Applicant Id" variant="outlined" fullWidth required type="number" onChange={(e) => settextid(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Buttonh type="submit" color="primary" variant="contained" fullWidth style={{ height: 55 }}> Search</Buttonh>
                </Grid>
              </Grid>
            </form>
            <br></br>
            {show &&
              <div id="applicantInfoSection" className="col-md-12 mt-5">
                <div className="table-responsive">
                  <table className="table table-striped" style={{ width: '100%' }}>
                    <tbody><tr>
                      <th>Photo</th>
                      <th>Student Name</th>
                      <td><span id="applicantName" /> {details?.applicantName}</td>
                    </tr>
                      <tr>
                        <td rowSpan={6}> <img src={"data:image/png;base64," + details?.imageName} height={150} width={150} id="imageName" /> </td>
                        <th>Application Date</th>
                        <td><span id="applicationDate" />{details?.applicationDate}</td>
                      </tr>
                      <tr>
                        <th>Class</th>
                        <td><span id="className" />{details?.className}</td>
                      </tr>
                      <tr>
                        <th>Group</th>
                        <td><span id="groupName" />{details?.groupName}</td>
                      </tr>
                      <tr>
                        <th>Mobile</th>
                        <td><span id="mobileNumber" />{details?.localGuardianMobile}</td>
                      </tr>
                      {/* <tr>
                      <th>Applicant Status</th>
                      <td><span id="applicantStatus" />{details?.applicantStatus}</td>
                    </tr> */}
                      <tr>
                        <th>Payment Status</th>
                        <td><span id="paymentStatus" />{paymentStatus === true ? <span style={{ color: 'green', fontWeight: 900 }} >Paid</span> : <span style={{ color: 'red', fontWeight: 900 }} >Unpaid</span>}</td>
                      </tr>
                    </tbody></table>
                </div>
                <br></br>
                <div className="d-flex align-item-center justify-content-center mt-3" style={{ float: "right" }}>
                  <Buttonh variant="contained" color="primary" onClick={() => downloadPdf()}> Download</Buttonh>
                  <Buttonh variant="contained" color="primary" disabled={paymentStatus} style={{ marginLeft: 15 }} onClick={() => paymentPage()} > Pay Now</Buttonh>
                </div>
              </div>
            }
            {!show && first &&
              <p className='MuiTypography-root MuiTypography-h5' style={{ color: "red", marginTop: 40, textAlign: "center" }}>Invalid applicant id, try again.</p>
            }
          </Container>

        </Grid>
      </div>
    </Grid>
  );
};

export default PaymentForm;
